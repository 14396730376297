
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {UserManualConsumeApi} from "@/common/api/dataWholeBoard/userManualConsumeRecord";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(UserManualConsumeApi);

function getCurrentWeekDate() {
  const currentDay = (dayjs() as any).format('YYYY-MM-DD');

  const curWeekStartDay = (dayjs().startOf("week") as any).format('YYYY-MM-DD');

  return [curWeekStartDay, currentDay];
}

const defaultForm = {
  member_recharge: "", // 累计会员充值
  spend_price: "", // 累计消费金额
  phone: "", // 手机号

  create_time: [
    ...getCurrentWeekDate()
  ], // 消费时间
 
};

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class UserManualConsumeList extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "手机号",
      field: "phone",
    },
    {
      label: '昵称',
      field: 'user_name'
    },
    {
      label: '最近消费店铺',
      field: 'shop_name'
    },
    {
      label: '累计会员充值',
      field: 'total_member_recharge'
    },
    {
      label: '累计消费',
      field: 'total_spend_price'
    },
    {
      label: '单店卡充值',
      field: 'total_shop_recharge'
    },
    {
      label: '单店卡消费',
      field: 'total_shop_spend_price'
    },
    {
      label: '开台时长（分钟）',
      field: 'total_minute'
    },
    {
      label: '注册日期',
      field: 'registry_time'
    }
    
  ];
  /** end 移动端相关属性 方法 */
  private userManualConsumeApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.userManualConsumeApi = new UserManualConsumeApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.userManualConsumeApi.getUserManualConsumeTableList(
      this.getUserManualConsumeTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  // 限制 累计会员充值金额
  private handleLimitMemberRecharge(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.form, 'member_recharge', new_val)
      },
      (new_val: string) => {
        this.$set(this.form, 'member_recharge', new_val)
      },
      2
    )
  }

  // 限制 消费金额
  private handleLimitUserConsume(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.form, 'spend_price', new_val)
      },
      (new_val: string) => {
        this.$set(this.form, 'spend_price', new_val)
      },
      2
    )
  }

  private getUserManualConsumeTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      member_recharge, // 累计会员充值
      spend_price, // 累计消费金额
      
      phone,
      create_time, // 消费时间
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (member_recharge) {
      outerParams.member_recharge = member_recharge;
    }

    if (spend_price) {
      outerParams.spend_price = spend_price
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择消费时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {

    } = data;
    const newData = {
      ...data,
    
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    "phone": "手机号",
    "user_name": "昵称",
    "shop_name": "最近消费店铺",
    "total_member_recharge": "累计会员充值",
    "total_spend_price": "累计消费",
    "total_shop_recharge": "单店卡充值",
    "total_shop_spend_price": "单店卡消费",
    "total_minute": "开台时长（分钟）",
    "registry_time": "注册日期"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "phone",
    "user_name",
    "shop_name",
    "total_member_recharge",
    "total_spend_price",
    "total_shop_recharge",
    "total_shop_spend_price",
    "total_minute",
    "registry_time"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserManualConsumeTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.userManualConsumeApi.exportExcelUserManualConsumeList(
      params,
      (res: any) => {
        const {
          start_date,
          end_date,
          // over_start_time,
          // over_end_time,
        } = params || {};

        const create_time_duration_name =
          start_date && end_date
            ? `消费时间${start_date}到${end_date}`
            : "";

        const filename = `用户台球消费记录（自助下单）列表 ${create_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                
              } = item || {};

              const newItem = {
                ...(item || {}),
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

         
          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}
